<template>

<iframe style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;width: 100%;height: 100%;" :src="chatUrl" frameborder="0"></iframe>


</template>

<script>
// import { getTeamsPatienceListApi } from "@/api/case";
import moment from "moment";

import { idCardRegExp, idCardFunction,getAge } from "@/utils";
export default {
  components: {

  },
  data() {
    return {
      sendPackageDialog: false, //发送方案
chatUrl:'',
      dataListSelections: [],
      currentPage4: 5,
      input3: "",
      form: {
        patienceName: "",

        patienceSex: "",

        mainDiagnosis: "",
      },
      typeArr: [],
      tableData: [],
      // columns: [
      //   {},
      //   { title: "姓名", field: "name" },
      //   { title: "性别", field: "sex" },
      //   { title: "年龄", field: "age" },
      //   { title: "联系方式", field: "phone" },
      //   { title: "诊断", field: "diagnose" },
      //   { title: "最近访视日期", field: "date" },
      //   {
      //     title: "操作",
      //     width: 300,
      //     fixed: "right",
      //     type: "btn",
      //     payload: [
      //       {
      //         name: "详情",
      //         type: "text",
      //         click: row => console.log("详情", row)
      //       }
      //     ]
      //   }
      // ],
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false, //列表loading
      tabData: [], //备用列表，在调用数据时使用
      dataList: [], //列表数据
      oldList: [],
      dateTime: ["", ""],
    };
  },
  mounted() {
 this.chatUrl = 'https://ky.docteams.cn/about/#/about?userId='+ JSON.parse(localStorage.userInfo).id.substring(0,16)+JSON.parse(localStorage.currentTeam).id.substring(0,16)+'&docFlag=true'
  },
  methods: {
    // async getDataList() {
    //   const res = await getTeamsPatienceListApi(this.params);
    //   console.log(res)
    // },
    // 发送方案弹窗
    showSendPackage(type, row) {
      let _this = this;
      let patienceIds = [];
      if (type == 1) {
        patienceIds = _this.dataListSelections.map((item) => {
          return item.patience_id;
        });
      } else {
        patienceIds.push(row.patience_id);
      }
      _this.sendPackageDialog = true;
      _this.$nextTick(() => {
        _this.$refs.sendPackage.init(patienceIds);
      });
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    async deleteHandle() {
      this.$confirm("确定进行[删除]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          const id = ids.join(`,`);
          const { data: res } = await this.$http({
            url: this.$http.adornUrl(`/teamsPatience/delete?ids=${id}`),
            method: "post",
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          }
        })
        .catch(() => {});
    },
    reset() {
      this.form = {
        patienceName: "",

        patienceSex: "",

        type: "",
      };
    },
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList();
    },
    getDataList() {
      let that = this;
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params = that.form;

      params.teamsId = teamsId;
      params.pageSize = that.pageSize;
      params.pageNo = that.pageNo;
      that.dataListLoading = true;
      that
        .$http({
          url: that.$http.adornUrl("/teamsPatience/selectTeamsPatience"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
            data.data.forEach((val) => {
              // if (val.patience_id_card) {
              //   val.patience_sex_name = idCardFunction(val.patience_id_card, 2);
              // }
              if (val.patience_birth) {
                val.patience_age = getAge(val.patience_birth);
              }else{
				  val.patience_sex_name = idCardFunction(val.patience_id_card, 2);
				  val.patience_birth = idCardFunction(val.patience_id_card, 1);
				  val.patience_age = idCardFunction(val.patience_id_card, 3);
			  }
            });
            that.tabData = data.list;
            that.dataList = data.data;
            that.oldList = data.data;
            that.dataListLoading = false;
            that.totalPage = data.totalCount;
          }
        });
    },
    getPorjType() {
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/dict/list?type=主要诊断"),
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.typeArr = data.data;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleClick(row) {
      if (row.patience_sex_name == "男") {
        row.patience_sex = 1;
      } else {
        row.patience_sex = 2;
      }
      localStorage.patientInfo = JSON.stringify(row);
      console.log(row);
      console.log(555, row);

      this.$router.push({
        name: "case-details",
        params: row,
      });
    },
    handleClickTwo() {
      this.$router.push("case/create");
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic {
  position: relative;

  .el-form {
    display: flex;

    .el-form-item {
      display: flex;
      margin-right: 48px;
      white-space: nowrap;

      .el-form-item__content {
        width: 240px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
      }
    }
  }

  .button {
    // float: left;

    // .el-button {
    // 	padding: 8px 23px;
    // 	height: 36px;
    // 	background: #A767FD;
    // 	border-radius: 2px;
    // }
  }

  // .el-table {
  //   position: absolute;
  //   top: 144px;
  // }
  // .el-pagination {
  //   margin-top: 371px;
  //   .el-pager .number {
  //     width: 32px;
  //     height: 32px;
  //     border-radius: 2px;
  //     border: 1px solid #d9d9d9;
  //   }
  //   .number:hover {
  //     background-color: #A767FD;
  //   }
  // }
}
</style>